@use "gilroyfont.scss";

@import "~bootstrap/scss/functions";

// @import "./core/custom";

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/bootstrap";

@import "./core/palette";

@import-normalize;

@import "./bootstrap/index.scss";

@import "./core/layout/content.scss";

@import "./components/navbar";
@import "./components/sidebar";

